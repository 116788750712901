@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

$primary: #5C4B51;/* MAIN COLOR */
$secondary: #8CBEB2; /* SECONDARY COLOR */
$blk: #000; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes.

$mod_1: 1.2; // mobile
$mod_2: 1.4; // desktop

html, body {
    height: 100%;
    font-family: 'Quicksand', sans-serif;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p, li {
    font-size: .8rem;
}

h1 {
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
  font-size: $mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 {
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            font-size: 1em;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 8px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: none;
                color: $primary;
                font-weight: bold;
                border-radius: 25px;
                border:1px solid $primary;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border-radius: 25px;
    background: none;
    color: $primary;
    border: 1px solid $primary;
    padding: 1em 2em;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: $primary;
        color: $wht;
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 100%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/
//section start
.delay-1 {
  animation-delay: .25s;
}
.delay-2 {
  animation-delay: .5s;
}
.delay-3 {
  animation-delay: .75s;
}
.delay-4 {
  animation-delay: 1s;
}


.box {

  background-color: rgba(0,0,0,.7);
  padding: 350px 0;
  @media (max-width: 1024px){
    padding: 250px 0;
  }
  @media (max-width: 767px){
    padding: 150px 0;
  }
  @media (max-width: 600px){
    padding: 100px 0;
  }
}
.bg{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @media(max-width: 1024px){
    background-attachment: scroll;
  }
}
.section-a {
  color: $wht;
  @extend .bg;
  background-image: url('../img/bg1.jpg');
}
.section-b {
  padding: 50px 0;
  background-color: $primary;
  img {
    border: 1px solid $secondary;
    padding: 20px;
    @media(max-width: 991px){
        margin-bottom: 20px;
    }
  }
}
.section-c {
  padding: 50px 0;
  background-color: $secondary;
  h4 {
    color: $primary;
    font-weight: bold;
    border-bottom: 1px solid $primary;
    border-top: 1px solid $primary;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  i {
    color: $wht;
  }
  p {
    color: darken($primary,20%);
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
}
.section-d{
  padding: 50px 0;
  background-color: lighten($primary, 40%);
  background-image: url("https://www.transparenttextures.com/patterns/back-pattern.png");
  color: $primary;
  h4{
    font-weight: bold;
    border-bottom: 2px dotted $primary;
    padding-bottom: 25px;
    font-size: 1.4em;
    line-height: 1.4em;

  }
}
//section end

footer {
    padding: 50px 0px 10px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-height: 100px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-height: 70px;
    }
    .navbar-header a {
        float: left;
    }
    .navbar-toggle {
        margin-top: 18px;
    }
}



.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
        font-size: 1rem;

    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}
